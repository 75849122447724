export default {
  en: {
    name: "DEMO CHAMBER",
    address: null
  },
  ar: {
    name: null,
    address: null
  },
  sp: {
    name: null,
    address: null
  },
  details: {
    prefix: null,
    abbrev: null,
    tel: null,
    webUrl: null
  },

  style: {
    logoIcon:null,
  },


  contacts: [
    {
      name: null,
      tel: null,
      email: null
    }
  ],
  ccFees: 3.85,

  //profit sharing partners
  partners: [],
  certificates: [
    {
      key: "cfs",
      label: "Certificate of Free Sale",
      clientFees: {
        en: { regular: 115, member: 75 },
        sp: { regular: 200, member: 100 },
        ar: { regular: 200, member: 100 }
      },
      chamberCharges: {
        certificateFees: {
          en: 10,
          sp: 20,
          ar: 20
        },
        surchargeFees: 0.4,
        scanFees: 0.4
      }
    },

    {
      key: "gmp",
      label: "Good Standing Certificate",
      availableLanguages: ["en", "ar", "sp"],
      clientFees: {
        en: { regular: 115, member: 75 },
        sp: { regular: 200, member: 100 },
        ar: { regular: 200, member: 100 }
      },
      chamberCharges: {
        certificateFees: {
          en: 10,
          sp: 20,
          ar: 20
        },
        surchargeFees: 0.4,
        scanFees: 0.4
      }
    },

    {
      key: "coo",
      label: "certificate of Origin",
      clientFees: {
        en: { regular: 110, member: 75 }
      },
      chamberCharges: {
        certificateFees: {
          en: 10,
          sp: 20,
          ar: 20
        },
        stampFees: 0.4
      }
    },

    {
      key: "cgs",
      label: "Certificate of Good Standing",
      clientFees: {
        en: { regular: 75, member: 50 }
      },
      chamberCharges: {
        certificateFees: {
          en: 5
        },
        scanFees: 0.4
      }
    }
  ],

  _partners: [
    {
      key: "escoc",
      label: "El Segundo Chamber of Commerce"
    },
    {
      key: "lacbffa",
      label: "Los Angeles Customs Brokers and Freight Forwarders Association"
    }
  ],

  _languages: [
    { key: "en", label: "English" },
    { key: "sp", label: "Spanish" },
    { key: "en", label: "Arabic" }
  ],
  _feeTypes: [
    { key: "regular", label: "Regular" },
    { key: "member", label: "Member" }
  ],
  _processingFees: [
    {
      key: "certificateFees",
      label: "Certificate Fees",
      multiLanguage: true
    },
    {
      key: "stampFees",
      label: "Stamping Fees",
      fee: 40
    },
    {
      key: "surchargeFees",
      label: "Stamping Fees",
      fee: 40
    },
    {
      key: "scanFees",
      label: "E-Mail Scanned copies",
      fee: 40
    }
  ]
};
