<template>
  <DataTable
    :value="logs"
    :paginator="false"
    class="p-datatable-customers"
    dataKey="id"
    :removableSort="true"
    responsiveLayout="scroll"
    :scrollable="true"
    scrollHeight="flex"
    :loading="loading"
    v-model:filters="filter"
    :globalFilterFields="['countryName']"
  >
    <template #loading>
      Loading country list
    </template>

    <template #header>
      <div class="fw-header">
        <div>
          <h6 class="mt-2 ml-2">
            <span class="p-menuitem-icon fas fa-globe-americas" aria-hidden="true"></span> Countries
          </h6>
        </div>

        <div class="w-full">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <InputText
              v-model="filter['global'].value"
              placeholder="Search"
              :class="'fullWidthInput'"
              type="search"
            />
          </span>
        </div>
        <div>
          <button
            class="p-button p-component p-button-icon-only p-button-rounded-light ml-2"
            type="button"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          >
            <span class="fa fa-filter p-button-icon" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </template>

    <Column field="countryCode" header="Country" :class="'text-center'">
      <template #body="{data}">
        <span class="flag" :class="'flag-' + data.countryCode.toLowerCase()"></span>
      </template>
    </Column>
    <Column field="countryName" header="Country" :sortable="true"></Column>
    <Column field="apostille" header="Apostille">
      <template #body="{data}">
        <i class="pi pi-check" v-if="data.apostille"></i>
        <i class="pi pi-times" v-if="!data.apostille"></i>
      </template>
    </Column>
    <Column field="secretaryofstate_fees" header="Secretary of State"></Column>
    <Column field="deptofstate_fees" header="Department of State"></Column>
    <Column field="embassy_fees" header="Embassy"></Column>
    <Column field="nusac_fees" header="NUSAC"></Column>
    <Column field="service_fees" header="Service Fees"></Column>
    <Column field="workdays" header="Processing Time"></Column>
  </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import "@/assets/styles/flags.css";

export default {
  components: {
    DataTable,
    Column
  },
  data() {
    return {
      logs: null,
      loading: false,
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      }
    };
  },
  created() {},
  mounted() {
    this.loading = true;
    axios.get("https://api.ftgs.io/country").then(response => {
      this.logs = response.data;
      this.logs.forEach((country, idx, countries) => {
        countries[idx].apostille = Boolean(Number(country.apostille));
      });
      this.loading = false;
    });
  },
  methods: {
    formatDate(value) {
      return value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
    },
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      });
    }
  }
};
</script>
