<template>
  <h6>Chambers of Commerce</h6>
  <DataTable
    :value="chambers"
    dataKey="cocId"
    responsiveLayout="scroll"
    :scrollable="true"
    scrollHeight="flex"
    :paginator="true"
    :rows="50"
    :loading="loading"
    showGridlines
    contextMenu
    v-model:contextMenuSelection="selectedChamber"
    @row-contextmenu="onRowContextMenu"
  >
    <Column field="coc" header="Chamber"> </Column>
  </DataTable>

  <ContextMenu :model="chamberMenu" ref="menu" />
</template>

<script>
import ContextMenu from "primevue/contextmenu";

export default {
  components: { ContextMenu },
  created() {
    this.getData();
  }, //created()

  data() {
    return {
      chambers: null,
      selectedChamber: null,
      loading: false,
      chamberMenu: [
        {
          label: "chamber",
          disabled: true,
          class: "title"
        },
        {
          label: "Settings",
          icon: "fas fa-cog",
          to: {path: ""}
          //command: () => this.viewCar(this.selectedCar)
        },
        {
          label: "Goto Chamber Website",
          icon: "fas fa-external-link",
          url: ""
          //command: () => this.deleteCar(this.selectedCar)
        }
      ]
    };
  },

  methods: {
    getData() {
      this.loading = true;
      this.axios.get("/system/chambers").then(response => {
        this.chambers = response.data;
        this.loading = false;
      }); //axios
    }, //getData()

    onRowContextMenu(event) {
      console.log(event);
      //update title
      this.chamberMenu[0].label = event.data.coc;

      //update path
        this.chamberMenu[1].to.path = "/chamber/"+event.data.cocId
    //update web
      this.chamberMenu[2].url = event.data.web;


      this.$refs.menu.show(event.originalEvent);
    }
  } //methods
};
</script>

<style scoped>
.title {
  background-color: greenyellow;
}
</style>
