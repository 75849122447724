<template>
  <div class="card y-scroll limitWidthCenter p-5">
    <div class="grid">
      <div class="col">
        <h1 class="el-h5 pb-3">Settings for {{ Config.en.name }}</h1>
        <!---<a href="#" @click.prevent="headsup('name')">XXX</a>--->
      </div>
    </div>
    <div class="card">
      <Divider align="left" class="divider-feature">
        <div class="p-d-inline-flex p-ai-center">
          Organization Details
          <a href="#" @click.prevent="headsup('name')"><i class="fas fa-question-circle"></i></a>
        </div>
      </Divider>

      <div class="grid">
        <div class="col-6">
          <div class="p-float-label my-3 ">
            <InputText
              id="name_en"
              type="text"
              class="inputfield w-full"
              v-model="Config.en.name"
            />
            <label for="name_en">Organization Name </label>
          </div>
        </div>
        <div class="col-4">
          <div class="p-float-label my-3 ">
            <InputText
              id="abrev"
              type="text"
              class="inputfield w-full"
              v-model="Config.details.abrev"
            />
            <label for="abrev">Abreviation</label>
          </div>
        </div>
        <div class="col-2">
          <div class="p-float-label my-3 ">
            <InputText
              id="prefix"
              type="text"
              class="inputfield w-full"
              v-model="Config.details.prefix"
            />
            <label for="prefix">Prefix</label>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="col-6">
          <div class="p-float-label my-3 ">
            <InputText
              id="address_en"
              type="text"
              class="inputfield w-full"
              v-model="Config.en.address"
            />
            <label for="address_en">Organization Address</label>
          </div>
        </div>
        <div class="col-3">
          <div class="p-float-label my-3">
            <InputText
              id="tel"
              type="text"
              class="inputfield w-full"
              v-model="Config.details.tel"
            />
            <label for="tel">Phone</label>
          </div>
        </div>
        <div class="col-3">
          <div class="p-float-label my-3">
            <InputText
              id="webURL"
              type="text"
              class="inputfield w-full"
              v-model="Config.details.webURL"
            />
            <label for="webURL">Website</label>
          </div>
        </div>
      </div>

      <Divider align="left" class="divider-feature">
        <div class="p-d-inline-flex p-ai-center">
          Translated Details
          <span class="el-caption"> (Optional)</span>
        </div>
      </Divider>

      <div class="grid">
        <div class="col-6">
          <div class="p-float-label my-3 ">
            <InputText
              id="name_ar"
              type="text"
              class="inputfield w-full"
              v-model="Config.ar.name"
            />
            <label for="name_ar">Arabic Name</label>
          </div>
        </div>
        <div class="col-6">
          <div class="p-float-label my-3 ">
            <InputText
              id="inputtext"
              type="text"
              class="inputfield w-full"
              v-model="Config.ar.address"
            />
            <label for="address_ar">Arabic Address</label>
          </div>
        </div>
      </div>
    </div>

    <div class="card my-2">
      <Divider align="left" class="divider-feature">
        <div class="p-d-inline-flex p-ai-center">
          Primary Contact
        </div>
      </Divider>

      <div class="grid" v-for="(contact, idx) in Config.contacts" :key="contact">
        <div class="col-4">
          <div class="p-float-label my-3">
            <InputText
              id="contact.name"
              type="text"
              class="inputfield w-full"
              v-model="Config.contacts[idx].name"
            />
            <label for="contact.name">Contact Name</label>
          </div>
        </div>
        <div class="col-4">
          <div class="p-float-label my-3">
            <InputText
              id="contact.tel"
              type="text"
              class="inputfield w-full"
              v-model="Config.contacts[idx].tel"
            />
            <label for="contact.tel">Contact Phone</label>
          </div>
        </div>
        <div class="col-4">
          <div class="p-float-label my-3">
            <InputText
              id="contact.email"
              type="text"
              class="inputfield w-full"
              v-model="Config.contacts[idx].email"
            />
            <label for="contact.email">Contact Email</label>
          </div>
        </div>
      </div>
    </div>

    <h1 class="el-h5 my-3">Certificates &#38; Fees</h1>
    <div v-for="(certificate, idx) in Config.certificates" :key="certificate" class="card mb-3">
      <div class="grid">
        <div class="col">
          <div class="el-h6">
            <Checkbox v-model="Config.certificates[idx].selected" :binary="true" class="mr-2" />
            {{ certificate.label }}
          </div>
        </div>
      </div>
      <Divider align="left" class="divider-feature">
        <div class="p-d-inline-flex p-ai-center">
          General Pricing
        </div>
      </Divider>
      <div class="pricing-grid">
        <div class="grid">
          <div class="col-2">Language</div>
          <div class="col-5">Regular</div>
          <div class="col-5">Member</div>
        </div>

        <div class="grid" v-for="(fee, key) in certificate.clientFees" :key="key">
          <div class="col-2">
            <Checkbox
              v-model="Config.certificates[idx].clientFees[key].selected"
              :binary="true"
              class="mr-2"
            />
            {{ key.toUpperCase() }}
          </div>

          <div class="col-5">
            <InputText
              id="primaryTextColor"
              type="text"
              class="inputfield text-right"
              placeholder="Regular Fee"
              v-model="Config.certificates[idx].clientFees[key].regular"
            />
          </div>

          <div class="col-5">
            <InputText
              id="primaryTextColor"
              type="text"
              class="inputfield text-right"
              placeholder="Member Fee"
              v-model="Config.certificates[idx].clientFees[key].member"
            />
          </div>
        </div>
      </div>
      <Divider align="left" class="divider-feature">
        <div class="p-d-inline-flex p-ai-center">Swiftdox Fees
        </div>
      </Divider>

      <p v-for="(chamberCharge, chargeType) in certificate.chamberCharges" :key="chamberCharge">
        <span v-if="chargeType === 'certificateFees'">
          <div class="formgrid grid">
            <span v-for="(charge, lang) in chamberCharge" :key="charge">
              <div class="p-float-label my-3 field col">
                <InputText
                  id="primaryTextColor"
                  type="text"
                  class="inputfield "
                  v-model="Config.certificates[idx].chamberCharges[chargeType][lang]"
                />
                <label for="primaryTextColor">Certificate Fees {{ lang }}</label>
              </div>
            </span>
          </div>
        </span>

        <span v-else>
          <div class="p-float-label my-3 field col">
            <InputText
              id="xxx"
              type="text"
              class="inputfield "
              v-model="Config.certificates[idx].chamberCharges[chargeType]"
            />
            <label for="xxx">{{ chargeType }} </label>
          </div>
        </span>
      </p>
    </div>
    <!--end v-for certificates -->
  </div>

  <Dialog v-model:visible="showDialog" :header="dialog.header" modal="true">
    {{ dialog.content }}
  </Dialog>
</template>

<script>
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Config from "./config.js";

export default {
  name: "Chamber Information",
  components: { Checkbox, Dialog, Divider },
  props: ["id"],
  data() {
    return {
      color: null,
      Config,
      showDialog: false,
      dialog: {},
      info: {
        name: {
          header: "Help name",
          content: "this is the help for name"
        }
      }
    }; //return
  }, //data()

  methods: {
    currency(number) {
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(number);
    }, //currency()

    headsup(topic) {
      this.dialog = this.info[topic];
      this.showDialog = true;
    }
  } //methods
};
</script>

<style scoped>
.y-scroll {
  max-height: 100%;
  overflow-y: scroll;
}

div.pricing-grid {
  width: 100%;
  max-width: 600px;
  margin-left: 30px;
}
</style>
